import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import TabLayout from "../TabLayout";

import { CompanyUrls } from "./api/readCompanyUrls";

import CompanyOverviewComponent from "./components/CompanyOverview";
import CompanySentimentComponent from "./components/CompanySentiment";
import CompanyFinancialsComponent from "./components/CompanyFinancials";
import RegistryDocumentsComponent from "./components/RegistryDocuments";
import CompanyUrlsComponent from "./components/CompanyUrls";
import AlternatingSearchBoxAndCompanyName from "./components/AlternatingSearchBoxAndCompanyName";
import { RegistryDocument } from "./api/listRegistryDocuments";
import background from "./background.jpg";
import { usePostHog } from "posthog-js/react";
import { useDealCreateDataStore } from "../../hooks/deals";
import { useNavigate } from "react-router-dom";
import info from "../../info";
import { useAuth0 } from "@auth0/auth0-react";

const CreateButton = ({ onClick }: { onClick?: () => void }) => {
  return (
    <button
      className="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
      onClick={onClick}
    >
      Save as Deal
    </button>
  );
};

const CompanySearchView = () => {
  const navigate = useNavigate();
  const posthog = usePostHog();
  const { user } = useAuth0();

  const [layout, setLayout] = useState<"simple" | "extended">("simple");
  const { orgId } = useParams();
  const [selectedCompany, setSelectedCompany] = useState<{
    companyName: string;
    countryCode: string;
    id: string;
  } | null>(null);
  const [logoDomain, setLogoDomain] = useState<string | null>(null);

  const [companyUrls, setCompanyUrls] = useState<CompanyUrls | null>(null);
  const [searchAccepted, setSearchAccepted] = useState<boolean>(false);
  const [registryDocuments, setRegistryDocuments] = useState<
    RegistryDocument[]
  >([]);
  const setDealData = useDealCreateDataStore((state) => state.setDealData);

  useEffect(() => {
    if (posthog.isFeatureEnabled("company_search_experimental_widgets")) {
      setLayout("extended");
    }
  }, [posthog]);

  useEffect(() => {
    setSearchAccepted(false);
    setRegistryDocuments([]);
    setCompanyUrls(null);
    setLogoDomain(null);
  }, [selectedCompany?.companyName]);

  useEffect(() => {
    if (companyUrls?.companyWebsite && companyUrls?.companyWebsite !== "") {
      setLogoDomain(new URL(companyUrls?.companyWebsite || "").hostname);
    }
  }, [companyUrls?.companyWebsite]);

  const handleCreateDealButtonClicked = () => {
    setDealData({
      companyName: selectedCompany?.companyName || "",
      companyId: selectedCompany?.id || "",
      companyAliases: [],
      website: companyUrls?.companyWebsite || null,
      registryFiles: registryDocuments.map((doc) => ({
        id: doc.documentId,
        registryIdentifier: doc.registryIdentifier,
        fileName: doc.documentName,
      })),
      countryCode: selectedCompany?.countryCode || "",
    });
    if (user) {
      posthog.capture("create_deal_from_company_search_clicked", {
        org_id: info.orgId,
        user_id: user.sub,
        user_email: user.email,
      });
    }
    navigate(`/orgs/${orgId}/save-deal`);
  };

  return (
    <TabLayout
      items={[{ name: "Search Company", href: null }]}
      buttons={[
        orgId ? <CreateButton onClick={handleCreateDealButtonClicked} /> : null,
      ]}
    >
      <div className="w-full content-center flex flex-col">
        <AlternatingSearchBoxAndCompanyName
          selectedCompany={selectedCompany}
          setSelectedCompany={setSelectedCompany}
          logo={logoDomain}
        />
        {selectedCompany && (
          <CompanyUrlsComponent
            legalName={selectedCompany.companyName}
            id={selectedCompany.id}
            approveDownload={() => setSearchAccepted(true)}
            updateCompanyUrls={(urls: CompanyUrls) => setCompanyUrls(urls)}
          />
        )}
        {!selectedCompany && (
          <div className="w-full h-full content-center flex flex-col justify-center mt-20">
            <div
              className="w-1/2 h-96 bg-no-repeat bg-center bg-cover opacity-10 mx-auto"
              style={{ backgroundImage: `url(${background})` }}
            />
          </div>
        )}
      </div>

      <div className="w-full h-full content-start grid grid-cols-12 px-4 pb-4 gap-2 auto-rows-max self-start">
        {searchAccepted && layout == "extended" && (
          <div className="col-span-4">
            <CompanyOverviewComponent
              legalName={selectedCompany?.companyName || ""}
              companyWebsite={companyUrls?.companyWebsite || ""}
            />
          </div>
        )}
        {searchAccepted && selectedCompany?.id && (
          <div
            className={`${layout == "extended" ? "col-span-4" : "col-span-6"} w-full`}
          >
            <CompanyFinancialsComponent id={selectedCompany?.id} />
          </div>
        )}
        {searchAccepted && layout == "extended" && (
          <div className="col-span-4">
            <CompanySentimentComponent
              legalName={selectedCompany?.companyName || ""}
              companyWebsite={companyUrls?.companyWebsite || ""}
            />
          </div>
        )}
        {searchAccepted && (
          <div
            className={`${layout == "extended" ? "col-span-12" : "col-span-6"} w-full`}
          >
            <RegistryDocumentsComponent
              id={selectedCompany?.id || ""}
              legalName={selectedCompany?.companyName || ""}
              countryCode={selectedCompany?.countryCode || ""}
              onDocumentListUpdated={setRegistryDocuments}
            />
          </div>
        )}
      </div>
    </TabLayout>
  );
};

export default CompanySearchView;
